// src/components/HomePage.js

import React from 'react';
import logo from './images/web_logo.png';

const businessEmail = "gerrydoesthat@gmail.com";
const emailSubject = "I feel I need to email you for some reason";

const HomePage = () => {
  return (
    <div>
      <header className='sticky-top'>
        {/* Navbar */}
        <nav className="navbar">
          <a className="navbar-brand" href="#home">
            <img
              src={logo}
              alt="Mozart Logo"
              width="150"
              height="auto"
              className="d-inline-block align-top"
            />
          </a>
          <div className="mx-auto text-center">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="#wheretheshirtsare">
                  Where There Are Shirts <span className="owo">OwO</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#wheretheshirtsarenot">
                  Where There Are NO Shirts
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#about">About</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">Contact</a>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      <section id="home">
        <h1>This is the way 🤖</h1>
        <a href="https://my-store-ff9659.creator-spring.com" target="_blank" rel="noopener noreferrer">
          <button className='button-49'>Show me cool stuff</button>
        </a>
      </section>

      {/* Featured stuff */}
      <section id="products" className="container">
        <div id="productCarousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="row justify-content-between">

                <div className="col-sm-6 col-md-4 col-lg-3">
                  <a
                    href="https://my-store-ff9659.creator-spring.com/listing/cap-and-america?product=2362&variation=106688"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-center d-block"
                  >
                    <img
                      src="https://my-store-ff9659.creator-spring.com/_next/image?url=https%3A%2F%2Fmockup-api.teespring.com%2Fv3%2Fimage%2F-Mpr4OKq2XA4rxciIoLcu287AOE%2F800%2F800.jpg&w=640&q=75"
                      alt="#Merica"
                      className="img-fluid mb-2"
                    />
                    <p>#Merica</p>
                  </a>
                </div>

                <div className="col-sm-6 col-md-4 col-lg-3">
                  <a
                    href="https://my-store-ff9659.creator-spring.com/listing/hashtag-blush"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-center d-block"
                  >
                    <img
                      src="https://my-store-ff9659.creator-spring.com/_next/image?url=https%3A%2F%2Fmockup-api.teespring.com%2Fv3%2Fimage%2F0Wu8GA9IOst1Thx-Xp9hvufFGWU%2F800%2F800.jpg&w=640&q=75"
                      alt="#BlushMug"
                      className="img-fluid mb-2"
                    />
                    <p>#BlushMug</p>
                  </a>
                </div>

                <div className="col-sm-6 col-md-4 col-lg-3">
                  <a
                    href="https://my-store-ff9659.creator-spring.com/listing/glasses-on-glasses?product=1386&variation=104649"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-center d-block"
                  >
                    <img
                      src="https://my-store-ff9659.creator-spring.com/_next/image?url=https%3A%2F%2Fmockup-api.teespring.com%2Fv3%2Fimage%2FWrHETYS9ozY7uzXxYmkuzNTFoeM%2F800%2F800.jpg&w=640&q=75"
                      alt="#GlassesOnGlasses"
                      className="img-fluid mb-2"
                    />
                    <p>#GlassesOnGlasses</p>
                  </a>
                </div>

                <div className="col-sm-6 col-md-4 col-lg-3">
                  <a
                    href="https://my-store-ff9659.creator-spring.com/listing/mozart-2024?product=1899&variation=105400"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-center d-block"
                  >
                    <img
                      src="https://my-store-ff9659.creator-spring.com/_next/image?url=https%3A%2F%2Fmockup-api.teespring.com%2Fv3%2Fimage%2FnG8QboSLFVErn06vZwqJJER53HE%2F800%2F800.jpg&w=640&q=75"
                      alt="#yep"
                      className="img-fluid mb-2"
                    />
                    <p>#Yep</p>
                  </a>
                </div>
              </div>
            </div>

            <div className="carousel-item">
              <div className="row justify-content-between">
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <a
                    href="https://my-store-ff9659.creator-spring.com/listing/shrugee?product=585&variation=102426"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-center d-block"
                  >
                    <img
                      src="https://my-store-ff9659.creator-spring.com/_next/image?url=https%3A%2F%2Fmockup-api.teespring.com%2Fv3%2Fimage%2FHqdzTGN4XG-7D2NHdHoObKap3CU%2F800%2F800.jpg&w=640&q=75"
                      alt="#shrugee"
                      className="img-fluid mb-2"
                    />
                    <p>#Shrugee</p>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <button className="carousel-control-prev" type="button" data-bs-target="#productCarousel" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#productCarousel" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </section>
      <hr></hr>

      {/* Where the shirts are */}
      <section id="wheretheshirtsare">
        <h2>Where the shirts live</h2>
        <div className="row">
          <div className="col-sm-6 col-md-4 col-lg-3">
            <a
              href="https://my-store-ff9659.creator-spring.com/listing/imwithrei?product=87&variation=2324"
              target="_blank"
              rel="noopener noreferrer"
              className="text-center d-block"
            >
              <img
                src="https://my-store-ff9659.creator-spring.com/_next/image?url=https%3A%2F%2Fmockup-api.teespring.com%2Fv3%2Fimage%2Fi_83lB0WsIjJSwyR3ywohq0-XFM%2F800%2F800.jpg&w=640&q=75"
                alt="#ImWithRei"
                className="img-fluid mb-2"
              />
              <p>#ImWithRei</p>
            </a>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <a
              href="https://my-store-ff9659.creator-spring.com/listing/hoyouawouadouwatoua?product=87&variation=2324"
              target="_blank"
              rel="noopener noreferrer"
              className="text-center d-block"
            >
              <img
                src="https://my-store-ff9659.creator-spring.com/_next/image?url=https%3A%2F%2Fmockup-api.teespring.com%2Fv3%2Fimage%2FPnd_ZE85brG7qL-gAxvG8vfgb9Q%2F800%2F800.jpg&w=640&q=75"
                alt="#Ninja"
                className="img-fluid mb-2"
              />
              <p>#MozartHiddenJutsu</p>
            </a>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <a
              href="https://my-store-ff9659.creator-spring.com/listing/rubbert-f?product=227&size=1248"
              target="_blank"
              rel="noopener noreferrer"
              className="text-center d-block"
            >
              <img
                src="https://my-store-ff9659.creator-spring.com/_next/image?url=https%3A%2F%2Fmockup-api.teespring.com%2Fv3%2Fimage%2FJ__eetpOOxJlv_I5fCuFmmdYamE%2F800%2F800.jpg&w=640&q=75"
                alt="#RubbertF"
                className="img-fluid mb-2"
              />
              <p>#RubbertFYourself</p>
            </a>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <a
              href="https://my-store-ff9659.creator-spring.com/listing/shrugee?product=11&variation=2489"
              target="_blank"
              rel="noopener noreferrer"
              className="text-center d-block"
            >
              <img
                src="https://my-store-ff9659.creator-spring.com/_next/image?url=https%3A%2F%2Fmockup-api.teespring.com%2Fv3%2Fimage%2F2Lb9okQJcEGE885HXj6jC30qeBA%2F800%2F800.jpg&w=640&q=75"
                alt="#Shrugee"
                className="img-fluid mb-2"
              />
              <p>#Shrugee</p>
            </a>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <a
              href="https://my-store-ff9659.creator-spring.com/listing/kurbo?product=212"
              target="_blank"
              rel="noopener noreferrer"
              className="text-center d-block"
            >
              <img
                src="https://my-store-ff9659.creator-spring.com/_next/image?url=https%3A%2F%2Fmockup-api.teespring.com%2Fv3%2Fimage%2FVml5u5gPg8dLnF7MMc83iVbfs1s%2F800%2F800.jpg&w=640&q=75"
                alt="#Kurbo"
                className="img-fluid mb-2"
              />
              <p>#KurboPower</p>
            </a>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <a
              href="https://my-store-ff9659.creator-spring.com/listing/mozart-2024?product=212"
              target="_blank"
              rel="noopener noreferrer"
              className="text-center d-block"
            >
              <img
                src="https://my-store-ff9659.creator-spring.com/_next/image?url=https%3A%2F%2Fmockup-api.teespring.com%2Fv3%2Fimage%2F7jHQhgjqtKloGsV181caV0LBNGQ%2F800%2F800.jpg&w=640&q=75"
                alt="#Mozart"
                className="img-fluid mb-2"
              />
              <p>#Mozart</p>
            </a>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <a
              href="https://my-store-ff9659.creator-spring.com/listing/kyukyu?product=2256&variation=106469"
              target="_blank"
              rel="noopener noreferrer"
              className="text-center d-block"
            >
              <img
                src="https://my-store-ff9659.creator-spring.com/_next/image?url=https%3A%2F%2Fmockup-api.teespring.com%2Fv3%2Fimage%2FWbdVVnjdbTVmZEp7QLvTNG7-Js8%2F800%2F800.jpg&w=640&q=75"
                alt="#Kyukyu"
                className="img-fluid mb-2"
              />
              <p>#Kyukyu</p>
            </a>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <a
              href="https://my-store-ff9659.creator-spring.com/listing/buy-timmy-turner?product=11&variation=2492"
              target="_blank"
              rel="noopener noreferrer"
              className="text-center d-block"
            >
              <img
                src="https://my-store-ff9659.creator-spring.com/_next/image?url=https%3A%2F%2Fmockup-api.teespring.com%2Fv3%2Fimage%2FYzG-VnpSlPdCho_EOg6BmtpOANE%2F800%2F800.jpg&w=640&q=75"
                alt="#TimmyTurner"
                className="img-fluid mb-2"
              />
              <p>#TimmyTurner</p>
            </a>
          </div>

        </div>
        <a href="https://my-store-ff9659.creator-spring.com/apparel" target="_blank" rel="noopener noreferrer">
          <button className='button-73'>Get me a shirt, immediately..</button>
        </a>
      </section>
      <hr></hr>

      {/* Where the shirts are not */}
      <section id="wheretheshirtsarenot">
        <h2>There are no shirts here</h2>
        {/* Home(stuff) */}
        <div className='row'>
          <div className="col-sm-6 col-md-4 col-lg-3">
            <a
              href="https://my-store-ff9659.creator-spring.com/listing/mozart-2024?product=2296&variation=106541"
              target="_blank"
              rel="noopener noreferrer"
              className="text-center d-block"
            >
              <img
                src="https://my-store-ff9659.creator-spring.com/_next/image?url=https%3A%2F%2Fmockup-api.teespring.com%2Fv3%2Fimage%2Fc7j1pU5qQYI7wWeWJGcBNMHZBLo%2F800%2F800.jpg&w=640&q=75"
                alt="#Mozart"
                className="img-fluid mb-2"
              />
              <p>#Mozart</p>
            </a>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <a
              href="https://my-store-ff9659.creator-spring.com/wall-art"
              target="_blank"
              rel="noopener noreferrer"
              className="text-center d-block"
            >
              <img
                src="https://my-store-ff9659.creator-spring.com/_next/image?url=https%3A%2F%2Fmockup-api.teespring.com%2Fv3%2Fimage%2FMUHXgjByXdtX-5ozfe1aOrBXVZ0%2F800%2F800.jpg&w=640&q=75"
                alt="#Kurbo"
                className="img-fluid mb-2"
              />
              <p>#KurboPower</p>
            </a>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <a
              href="https://my-store-ff9659.creator-spring.com/listing/timmy-turner-stage-3?product=597&variation=102549"
              target="_blank"
              rel="noopener noreferrer"
              className="text-center d-block"
            >
              <img
                src="https://my-store-ff9659.creator-spring.com/_next/image?url=https%3A%2F%2Fmockup-api.teespring.com%2Fv3%2Fimage%2F7csS2RsgIfad4ly_KzMp8Dq89mg%2F800%2F800.jpg&w=640&q=75"
                alt="#TimmyTurner3"
                className="img-fluid mb-2"
              />
              <p>#TimmyTurnerStage3</p>
            </a>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <a
              href="https://my-store-ff9659.creator-spring.com/listing/timmy-turner-stage-2?product=721&variation=103184"
              target="_blank"
              rel="noopener noreferrer"
              className="text-center d-block"
            >
              <img
                src="https://my-store-ff9659.creator-spring.com/_next/image?url=https%3A%2F%2Fmockup-api.teespring.com%2Fv3%2Fimage%2Fd6Rjx0qcKCO6GbbGZQB0D4pO0Mw%2F800%2F800.jpg&w=640&q=75"
                alt="#TimmyTurner2"
                className="img-fluid mb-2"
              />
              <p>#TurntDownForWhat?</p>
            </a>
          </div>
        </div>
        <a href="https://my-store-ff9659.creator-spring.com/homeware" target="_blank" rel="noopener noreferrer">
        <button className='button-73'>Home Stuff</button>
        </a>

        {/* NotHome */}
        <div className='row'>
          <div className="col-sm-6 col-md-4 col-lg-3">
            <a
              href="https://my-store-ff9659.creator-spring.com/listing/zeldaxrubbertf?product=972&variation=103980"
              target="_blank"
              rel="noopener noreferrer"
              className="text-center d-block"
            >
              <img
                src="https://my-store-ff9659.creator-spring.com/_next/image?url=https%3A%2F%2Fmockup-api.teespring.com%2Fv3%2Fimage%2FKaT8RhPDhpbN49qsGOkbA0qUwhc%2F800%2F800.jpg&w=640&q=75"
                alt="#Mozart"
                className="img-fluid mb-2"
              />
              <p>#ZeldaXRubbertF</p>
            </a>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <a
              href="https://my-store-ff9659.creator-spring.com/listing/anxious-axel?product=1540&variation=104872"
              target="_blank"
              rel="noopener noreferrer"
              className="text-center d-block"
            >
              <img
                src="https://my-store-ff9659.creator-spring.com/_next/image?url=https%3A%2F%2Fmockup-api.teespring.com%2Fv3%2Fimage%2FCSJcTQ2OnPm55DAVdUKJke-EnjI%2F800%2F800.jpg&w=640&q=75"
                alt="#Axel"
                className="img-fluid mb-2"
              />
              <p>#AnxiousAxel</p>
            </a>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <a
              href="https://my-store-ff9659.creator-spring.com/listing/mozart-2024?product=933&variation=103879"
              target="_blank"
              rel="noopener noreferrer"
              className="text-center d-block"
            >
              <img
                src="https://my-store-ff9659.creator-spring.com/_next/image?url=https%3A%2F%2Fmockup-api.teespring.com%2Fv3%2Fimage%2FWw8fyjj1n18ynaVNJt99ErWWNCY%2F800%2F800.jpg&w=640&q=75"
                alt="#Mozart"
                className="img-fluid mb-2"
              />
              <p>#MozartGotBags</p>
            </a>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <a
              href="https://my-store-ff9659.creator-spring.com/listing/buy-timmy-turner?product=1018&variation=104055"
              target="_blank"
              rel="noopener noreferrer"
              className="text-center d-block"
            >
              <img
                src="https://my-store-ff9659.creator-spring.com/_next/image?url=https%3A%2F%2Fmockup-api.teespring.com%2Fv3%2Fimage%2FTDuEr_m0QIewYlRv_CoHWaVnWvU%2F800%2F800.jpg&w=640&q=75"
                alt="#TimmyTurner2"
                className="img-fluid mb-2"
              />
              <p>#WhoYouCallingFanny?</p>
            </a>
          </div>
        </div>
        <a href="https://my-store-ff9659.creator-spring.com/accessories" target="_blank" rel="noopener noreferrer">
        <button className='button-73'>Not home stuff</button>
        </a>
        <hr></hr>

        {/* Drink?Where?*/}
        <div className='row'>
          <div className="col-sm-6 col-md-4 col-lg-3">
            <a
              href="https://my-store-ff9659.creator-spring.com/listing/timmy-turner-stage-3?product=1565&variation=104922"
              target="_blank"
              rel="noopener noreferrer"
              className="text-center d-block"
            >
              <img
                src="https://my-store-ff9659.creator-spring.com/_next/image?url=https%3A%2F%2Fmockup-api.teespring.com%2Fv3%2Fimage%2Flj-RBt8fskZxsEkpw163q7dTvCk%2F800%2F800.jpg&w=640&q=75"
                alt="#TimmyTurner3"
                className="img-fluid mb-2"
              />
              <p>#TimmyTurnUpTheTemp</p>
            </a>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <a
              href="https://my-store-ff9659.creator-spring.com/listing/timmy-turner-stage-2?product=2301&variation=106588"
              target="_blank"
              rel="noopener noreferrer"
              className="text-center d-block"
            >
              <img
                src="https://my-store-ff9659.creator-spring.com/_next/image?url=https%3A%2F%2Fmockup-api.teespring.com%2Fv3%2Fimage%2FoRqfyqm8wma4ONl_z10Vc7jaQzM%2F800%2F800.jpg&w=640&q=75"
                alt="#turnt"
                className="img-fluid mb-2"
              />
              <p>#Turnt</p>
            </a>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <a
              href="https://my-store-ff9659.creator-spring.com/listing/kyukyu?product=1565&variation=104926"
              target="_blank"
              rel="noopener noreferrer"
              className="text-center d-block"
            >
              <img
                src="https://my-store-ff9659.creator-spring.com/_next/image?url=https%3A%2F%2Fmockup-api.teespring.com%2Fv3%2Fimage%2FIVOuh55-xx2yvdTKxQusv1NoTZ4%2F800%2F800.jpg&w=640&q=75"
                alt="#KyuKyu"
                className="img-fluid mb-2"
              />
              <p>#KyuKyu</p>
            </a>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <a
              href="https://my-store-ff9659.creator-spring.com/listing/glasses-on-glasses?product=1386&variation=104649"
              target="_blank"
              rel="noopener noreferrer"
              className="text-center d-block"
            >
              <img
                src="https://my-store-ff9659.creator-spring.com/_next/image?url=https%3A%2F%2Fmockup-api.teespring.com%2Fv3%2Fimage%2FWrHETYS9ozY7uzXxYmkuzNTFoeM%2F800%2F800.jpg&w=640&q=75"
                alt="#GlassesOnGlasses"
                className="img-fluid mb-2"
              />
              <p>#GlassesOnGlasses?</p>
            </a>
          </div>
        </div>
        <a href="https://my-store-ff9659.creator-spring.com/Drinkware" target="_blank" rel="noopener noreferrer">
        <button className='button-73'>Stuff you drink from in your home or not in your home</button>
          </a>
      </section>
      <hr></hr>

      {/* About the about section */}
      <section id="about">
        <h5>About section where I tell you about stuff and what stuff is about</h5>
        <p style={{ fontWeight: 'bold', color: '#5eccd1' }}>That's about it!</p>
      </section>
      <hr></hr>

      {/* Contact(s) */}
      <section id="contact">
        <a
          href={`mailto:${businessEmail}?subject=${encodeURIComponent(emailSubject)}`}
        >
          <button className='button-85'>Contact us, if you must..</button>
        </a>
      </section>
      <hr></hr>

      {/* Foot, loose */}
      <footer>
        <p>&copy; 2024 Moz'Art shirts 'n stuff. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default HomePage;
