import './App.css';
import HomePage from './HomePage';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


function App() {
  return (
    <div className="App">
      <HomePage />
    </div>
  );
}

export default App;
